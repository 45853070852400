import React, { useEffect } from 'react';

export default function KuulaProject(props) {

	useEffect(() => {
		// console.log('window', window)
	}, []);

	return (
	<div>
		<iframe className="ku-embed" 
			title="Punk Album"
			frameBorder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" 
			src="https://kuula.co/share/collection/7kVqz?logo=-1&info=0&fs=0&vr=0&thumbs=-1&margin=5&inst=0&keys=0"></iframe>
	</div>
	)

}

